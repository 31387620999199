import { API_BASE_URL } from "./constants";

const TOKEN_LOCAL_STORAGE_KEY = 'token';

export const handleAuth = () => {
  window.location.href = `${API_BASE_URL}/auth/github`;
};

export const logout = () => {
  localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
  window.location.href = '/';
};

export const setToken = (token) => {
  localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
}

export const getToken = () => {
  return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
}