// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getToken, setToken as saveToken, logout as performLogout } from "../services/auth.service";

/**
 * @typedef {Object} AuthContextValue
 * @property {string|null} token - Authentication token
 * @property {(token: string) => void} login - Function to handle user login
 * @property {() => void} logout - Function to handle user logout
 */

/** @type {React.Context<AuthContextValue>} */
const AuthContext = createContext();

/**
 * Hook to access auth context
 * @returns {AuthContextValue} The auth context value
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

/**
 * Auth context provider component
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => getToken());

  useEffect(() => {
    const storedToken = getToken();
    setToken(storedToken);
  }, []);

  const login = (newToken) => {
    saveToken(newToken);
    setToken(newToken);
  };

  const logout = () => {
    performLogout();
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
