import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProfileProvider } from "./context/ProfileContext";
import { AuthProvider } from "./context/AuthContext";
import { AnalyticsProvider } from './context/AnalyticsContext';
import { FollowersChangesProvider, FollowersCountProvider } from "./context/FollowersHistoryContext";

const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const Navbar = React.lazy(() => import("./components/Navbar"));
const Footer = React.lazy(() => import("./components/Footer"));
const ProfilePage = React.lazy(() =>
  import("./pages/ProfilePage")
);

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <main className="flex-grow">
            <Suspense
              fallback={
                <div className="flex justify-center items-center min-h-[50vh]">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                  path="/profile/*"
                  element={
                    <ProfileProvider>
                      <FollowersChangesProvider>
                        <FollowersCountProvider>
                          <AnalyticsProvider>
                            <ProfilePage />
                          </AnalyticsProvider>
                        </FollowersCountProvider>
                      </FollowersChangesProvider>
                    </ProfileProvider>
                  }
                />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
