import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fetchProfileData } from "../services/api.service";
import { getToken, setToken as saveToken, logout as performLogout } from "../services/auth.service";

/**
 * @typedef {Object} ProfileContextValue
 * @property {Awaited<ReturnType<typeof fetchProfileData>>|null} profileData - The user's profile data
 * @property {string|null} token - Authentication token
 * @property {(token: string) => void} setToken - Function to update the token
 * @property {boolean} loading - Loading state
 * @property {string|null} error - Error message if any
 * @property {() => void} handleLogout - Function to handle user logout
 */

/** @type {React.Context<ProfileContextValue>} */
const ProfileContext = createContext();

/**
 * Hook to access profile context
 * @returns {ProfileContextValue} The profile context value
 */
export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }
  return context;
};

/**
 * Profile context provider component
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(() => getToken());
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    performLogout();
    setToken(null);
    setProfileData(null);
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const queryToken = searchParams.get("token");
    if (queryToken) {
      saveToken(queryToken);
      setToken(queryToken);
      navigate("/profile", { replace: true });
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    let mounted = true;

    const fetchProfile = async () => {
      setLoading(true);
      setError(null);

      if (!token) {
        setProfileData(null);
        setLoading(false);
        return;
      }

      try {
        const data = await fetchProfileData();
        if (mounted) {
          setProfileData(data);
        }
      } catch (error) {
        if (mounted) {
          setError(error.message);
          if (error.response?.status === 401) {
            handleLogout();
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchProfile();
    return () => { mounted = false; };
  }, [token, handleLogout]);

  const value = {
    profileData,
    token,
    setToken,
    loading,
    error,
    handleLogout
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};
