import axios from 'axios';
import { API_BASE_URL } from './constants';
import { getToken, logout } from './auth.service';

const api = axios.create({ baseURL: API_BASE_URL, });

// Request interceptor for adding auth token
api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor for handling auth errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);
/**
 * Fetches the authenticated user's profile data from the server.
 * @async
 * @returns {Promise<{
 *   id: number,
 *   email: string,
 *   name: string,
 *   username: string,
 *   avatarUrl: string,
 *   company: string,
 *   location: string,
 *   bio: string,
 *   createdAt: string,
 *   lastLoginAt: string
 *   followers: number
 *   following: number
 * }>} The user's profile data
 * @throws {Error} If the request fails or returns an error status.
 */
export const fetchProfileData = async () => {
  const response = await api.get('/my-profile');
  return response.data;
};

/**
 * Fetches the user's followers changes history from the server.
 * @async
 * @param {number} [page=1] - The page number for pagination.
 * @param {number} [limit=15] - The number of items per page.
 * @returns {Promise<{
 *   followersChangeHistory: Array<{
 *     id: number,
 *     userGitHubId: number,
 *     followerGitHubId: number,
 *     followerName: string | null,
 *     followerLogin: string,
 *     followerEmail: string,
 *     changeType: string,
 *     createdAt: string
 *   }>
 * }>} The user's followers changes history
 * @throws {Error} If the request fails or returns an error status.
 */
export const fetchFollowersChangesHistory = async (page = 1, limit = 15) => {
  const response = await api.get('/followers/changes-history', {
    params: { page, limit }
  });
  return response.data;
};

/**
 * Fetches the user's followers count history from the server.
 * @async
 * @returns {Promise<{
 *   followersCountHistory: Array<{
 *     id: number,
 *     count: number,
 *     userGitHubId: number,
 *     createdAt: string
 *   }>
 * }>} The user's followers count history
 * @throws {Error} If the request fails or returns an error status.
 */
export const fetchFollowersCountHistory = async () => {
  const response = await api.get('/followers/count-history');
  return response.data;
};

/**
 * Fetches the user's followers analytics data from the server.
 * @async
 * @returns {Promise<{
 *   analytics: {
 *     total: {
 *       gained: number,
 *       lost: number,
 *       net: number
 *     },
 *     changes: {
 *       sevenDays: {
 *         gained: number,
 *         lost: number
 *       },
 *       thirtyDays: {
 *         gained: number,
 *         lost: number
 *       }
 *     },
 *     insights: {
 *       mostGainedDay: {
 *         date: string | null,
 *         count: number
 *       },
 *       mostLostDay: {
 *         date: string | null,
 *         count: number
 *       },
 *       lastGainedDate: string | null,
 *       lastLostDate: string | null
 *     }
 *   }
 * }>} The user's followers analytics data
 * @throws {Error} If the request fails or returns an error status.
 */
export const fetchAnalyticsData = async () => {
  const response = await api.get('/followers/analytics');
  return response.data;
};
