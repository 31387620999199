import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchFollowersChangesHistory, fetchFollowersCountHistory } from "../services/api.service";
import { useAuth } from "./AuthContext";

const FollowersChangesContext = createContext();
const FollowersCountContext = createContext();

export const useFollowersChanges = () => useContext(FollowersChangesContext);
export const useFollowersCount = () => useContext(FollowersCountContext);

export const FollowersChangesProvider = ({ children }) => {
  const [changesHistory, setChangesHistory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const data = await fetchFollowersChangesHistory(currentPage);
          setChangesHistory(data);
        } catch (error) {
          console.error("Failed to fetch followers changes:", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [token, currentPage]);

  return (
    <FollowersChangesContext.Provider 
      value={{ 
        changesHistory, 
        currentPage, 
        setCurrentPage,
        isLoading
      }}
    >
      {children}
    </FollowersChangesContext.Provider>
  );
};

export const FollowersCountProvider = ({ children }) => {
  const [countHistory, setCountHistory] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          const data = await fetchFollowersCountHistory();
          setCountHistory(data);
        } catch (error) {
          console.error("Failed to fetch followers count:", error);
        }
      };
      fetchData();
    }
  }, [token]);

  return (
    <FollowersCountContext.Provider value={{ countHistory }}>
      {children}
    </FollowersCountContext.Provider>
  );
};
