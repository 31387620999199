import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAnalyticsData } from "../services/api.service";
import { useProfile } from "./ProfileContext";

/**
 * @typedef {Object} AnalyticsContextValue
 * @property {Awaited<ReturnType<typeof fetchAnalyticsData>>|null} analyticsData - The analytics data from API
 * @property {boolean} loading - Loading state
 * @property {string|null} error - Error message if any
 */

/** @type {React.Context<AnalyticsContextValue>} */
const AnalyticsContext = createContext();

/**
 * Hook to access analytics context
 * @returns {AnalyticsContextValue} The analytics context value
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};

/**
 * Analytics context provider component
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export const AnalyticsProvider = ({ children }) => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useProfile();

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (!token) {
        setAnalyticsData(null);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const data = await fetchAnalyticsData();
        if (mounted) {
          setAnalyticsData(data);
        }
      } catch (err) {
        if (mounted) {
          setError(err.message);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();
    return () => { mounted = false; };
  }, [token]);

  const value = {
    analyticsData,
    loading,
    error
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
